.mainUrl {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 26px;
  grid-template-areas:
    'button hline hline dropdown dropdown dropdown h2line  destination destination . . .'
    'vline . . . . . . . . . . .'
    'vline . . . . . . . . . . .'
    'label hbline hbline labelInput labelInput . . . . . . .';
  height: 200px;
  margin-top: 20px;
}

.mainAck {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 26px;
  grid-template-areas:
    'button hline hline dropdown dropdown dropdown dropdown . . . h2line destination'
    'vline . . . . . . . . . . .'
    'vline . . . . . . . . . . .'
    'label hbline hbline labelInput labelInput . . . . . . .';
  height: 200px;
  margin-top: 20px;
}

.button {
  grid-area: button;
}

.vline {
  grid-area: vline;
  justify-self: center;
}

.hline {
  grid-area: hline;
  align-self: center;
}

.h2line {
  grid-area: h2line;
  align-self: center;
}

.hbline {
  grid-area: hbline;
  margin-top: 10px;
}

.dropdown {
  grid-area: dropdown;
  align-self: center;
}

.label {
  justify-self: end;
  grid-area: label;
}

.destination {
  align-self: flex-start;
  margin-top: -35px;
  grid-area: destination;
}

.spekDestination {
  align-self: center;
  grid-area: destination;
  width: 200px;
}

.labelInput {
  align-self: flex-start;
  width: 96px;
  margin-top: -40px;
  grid-area: labelInput;
}
