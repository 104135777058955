.container {
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 357px;
  padding: 0 14px;
}

.trashIcon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  background-color: #9fa9ba;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trashIcon:hover {
  background-color: #738495;
}

.emptyButton {
  height: 45px !important;
  width: 142px !important;
  background-color: #fff !important;
  color: #56606a !important;
  border-radius: 30px !important;
  border: 1px solid #56606a !important;
  font-size: 18px !important;
}

.pinkButton {
  height: 45px !important;
  width: 142px !important;
  background-color: #c673d2 !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 18px !important;
}

.saveIcon {
  color: #fff;
  margin-right: 0.3em;
}

.editButton {
  height: 45px !important;
  width: 113px !important;
  font-size: 16px !important;
  letter-spacing: 0.11px !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  background-color: #3576e8 !important;
}
.subContainerEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 312px;
  padding: 0 14px;
}

.subContainerRead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  padding: 0 14px;
}

.pinkButton:hover {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.emptyButton:hover {
  background-color: #f6f7f9 !important;
}
