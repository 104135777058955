.horizontal {
  height: 0px;
  width: 100%;
  border: 1px solid #9e9e9e;
  flex-grow: 1;
}

.vertical {
  width: 0px;
  height: 100%;
  flex-grow: 1;
  border: 1px solid #9e9e9e;
}
