.main {
  color: #333333;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 4px;
  background-color: #c6cbd4;
}
