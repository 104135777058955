.container {
  padding: 10px;
  margin-top: 15px;
}

.header {
  font-size: 16px;
  letter-spacing: 0.11px;
  color: #637280;
  margin-bottom: 30px;
}

.headingContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.heading {
  font-size: 14px;
  color: #5b7584;
  font-weight: bold;
  letter-spacing: 0;
}

.infoCircle {
  font-size: 14px;
  color: #a3b0c1;
  margin-left: 0.5em;
}

.rulesContainer {
  margin-top: 10px;
  position: relative;
}

.rulesContainerInside {
  margin-top: 10px;
  height: 72px;
  position: relative;
  margin-left: 25px;
}

.ruleSelector {
  width: 150px;
}

.rulesAddInput {
  margin-left: 25px;
  position: relative;
}

.addRuleBtn {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  width: 137px;
  border: 1px solid #3576e8;
  border-radius: 20.16px;
  background-color: rgba(53, 118, 232, 0.1);
  color: #1c7bee;
  font-size: 12px;
  letter-spacing: 0.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addRuleBtnDisabled {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  width: 137px;
  border: 1px solid #c4c3d0;
  border-radius: 20.16px;
  background-color: #fff;
  color: #c4c3d0;
  font-size: 12px;
  letter-spacing: 0.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: none;
}

.plusCircle {
  font-size: 12px;
  margin-right: 0.3em;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexRowRules {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.flexColumnLast {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  margin-left: 28px;
  margin-top: -7px;
  margin-bottom: -3px;
}
.flexRowOr {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
.flexColumnRules {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  margin-top: -7px;
  margin-bottom: -7px;
}

.subRulesContainer {
  display: flex;
  min-height: 20px;
}

.pathSelector {
  margin-left: 230px;
  min-height: 48px;
  position: relative;
}

.pathInsideContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.pathContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.trashIcon {
  margin-left: 0.5em;
  color: #637280;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
}

.vline {
  position: absolute;
  border: 1px solid #9e9e9e;
  height: 67px;
  top: 0;
  width: 0;
}

.link {
  text-decoration: none;
  color: #3576e8;
  margin-right: 0.5em;
}

.icon {
  margin-left: 0.3em;
}

.radioContainer {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.radioContainer > * {
  margin: 0 4px;
}

.radioContainer > input[type='radio'] {
  margin: 0 10px;
  height: 24px;
}

.radioContainer > label {
  color: #56606a;
  font-size: 14px;
}

.transition {
  transition: all 300ms ease-in-out;
}

.checkboxLabelWrapper {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.checkboxLabelWrapper label {
  font-size: 12px;
  margin-left: 5px;
  color: #9e9e9e;
}
