.main {
  display: flex;
  height: 45.6px;
  color: #637280;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  background-color: #e6e6e6;
  font-size: 16px;
}

.icon {
  font-size: 28px;
  color: #c0c5d2;
  cursor: pointer;
}
